export default {
  namespaced: true,
  state: {
    address: '',
    denom: '',
    amount: '',
    isImported: false
  },
  mutations: {
    RESET_DATA(state) {
      state.address = '';
      state.denom = '';
      state.amount = '';
      state.isImported = false;
    },
    // SET_DATA(state, options) {
    //   state.address = options.address;
    //   state.amount = options.amount;
    // }
  },
  actions: {
    reset(context) {
      context.commit('RESET_DATA');
    },
    // setWallet(context, options) {
    //   context.commit('SET_DATA', {
    //     address: options.address,
    //     amount: options.amount
    //   });
    // }
  }
}