import { createApp } from 'vue'
import 'regenerator-runtime/runtime'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import STable from '@surely-vue/table'

// scss
import '/src/assets/scss/main.scss'
import 'ant-design-vue/dist/antd.css'
import '@surely-vue/table/dist/index.css'


const app = createApp(App);
app.use(store);
app.use(router);
app.use(Antd);
app.use(STable);

app.mount('#app')
