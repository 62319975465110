export default {
  namespaced: true,
  state: {
    isLogin: false,
    token: "",

    info: {}
  },
  mutations: {
    RESET_AUTH(state) {
      state.isLogin = false;
      state.token = "";
      state.info = {};
    },
    SET_AUTH(state, options) {
      state.isLogin = true;
      state.token = options.token;
      state.info = options.info;
    }
  },
  actions: {
    reset(context) {
      context.commit('RESET_AUTH');
    },
    setAuth(context, options) {
      context.commit('SET_AUTH', {
        token: options.token,
        info: options.info
      });
    }
  }
}