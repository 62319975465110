import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '',
    redirect: '/auth/login'
  },

  /* Auth */
  {
    path: '/auth/login',
    name: 'AuthLogin',
    component: () => import('../pages/Auth/Login')
  },

  /* App */
  {
    path: '/app',
    component: () => import('../layouts/app'),
    children: [{
      path: '',
      name: 'AppIndex',
      component: () => import('../pages/App/Index')
    }, {
      path: 'members',
      name: 'AppMembers',
      component: () => import('../pages/App/Members')
    }, {
      path: 'exchanges',
      name: 'AppExchanges',
      component: () => import('../pages/App/Exchanges')
    }, {
      path: 'transaction',
      name: 'AppTransaction',
      component: () => import('../pages/App/Exchanges/Transaction.vue')
    }, {
      path: 'accounting',
      name: 'AppAccounting',
      component: () => import('../pages/App/Exchanges/Accounting.vue')
    }, {
      path: 'fee-accounting',
      name: 'AppFeeAccounting',
      component: () => import('../pages/App/FeeAccounting')
    }, {
      path: 'records',
      name: 'AppTransferRecords',
      component: () => import('../pages/App/Records')
    }]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
