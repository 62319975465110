// import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import init from './config'

import auth from './modules/auth'
import wallet from './modules/wallet'

const store = createStore({
  strict: false,
  modules: {
    auth,
    wallet
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
      reducer(val) {
        return {
          auth: val.auth,
          wallet: val.wallet
        }
     }
  })]
})
init(store)

export default store